.rich-text {
  margin: auto;
  text-align: center;
}

.rich-text.rich-text--full-width {
  max-width: initial;
  width: 100%;
}

.rich-text__blocks {
  margin: auto;
  /* 2.5rem margin on left & right */
  width: calc(100% - 5rem);
}

.rich-text__blocks * {
  overflow-wrap: break-word;
}

.rich-text--full-width .rich-text__blocks {
  /* 4rem (1.5rem + 2.5rem) margin on left & right */
  width: calc(100% - 8rem);
}

.rich-text:not(.rich-text--full-width),
.rich-text--full-width.color-background-1 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.rich-text:not(.color-background-1) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (min-width: 750px) {
  .rich-text__blocks {
    max-width: 50rem;
  }

  .rich-text--full-width .rich-text__blocks {
    /* 7.5rem (5rem + 2.5rem) margin on left & right */
    width: calc(100% - 15rem);
  }
}

@media screen and (min-width: 990px) {
  .rich-text__blocks {
    max-width: 78rem;
  }
}

/* Blocks */

.rich-text__blocks > * {
  margin-top: 0;
  margin-bottom: 0;
}

.rich-text__blocks > * + * {
  margin-top: 2rem;
}

.rich-text__blocks > * + a {
  margin-top: 3rem;
}
